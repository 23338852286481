import styled, { css } from "styled-components";
import { Flex } from "src/styles/components";
import { theme } from "src/styles/global";

const size = css`
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  min-width: 140px;
  min-height: 140px;
  
  @media (${theme.device.mobile}) {
    min-height: 150px;
    min-width: 150px;
  }
`;

export const Label = styled.span`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
  transform: rotate(
    ${({ direction }) => (direction === "right" ? "-15deg" : "15deg")}
  );
  text-align: center;
  transition: transform 0.3s;

  @media (max-width: 640px) {
    font-size: 16px;
    transform: rotate(
            ${({ direction }) => (direction === "left" && "0deg")}
    );
  }
`;

const drawCircle = (number, opacity) => css`
  border: 1px solid rgba(255, 255, 255, ${opacity});
  ${({ direction }) => direction}: ${number * 32}px;
  @media (${theme.device.mobile}) {
    ${({ direction }) => direction}: ${number * 28}px;
  }
  &.start {
    ${({ direction }) => direction}: 0;
    transition-delay: 2s;
  }

  /* &.start {
    animation: ${({ direction }) => direction + number} 1s ${2}s ease-in-out
      forwards;
    @media (${theme.device.tablet}) {
      animation: ${({ direction }) => direction + number} 1s ${2}s ease-in-out
        0s forwards;
    }
    @media (${theme.device.mobile}) {
      animation: ${({ direction }) => direction + number} 1s ${2}s ease-in-out
        0s forwards;
    }
  }

  @keyframes ${({ direction }) => direction + number} {
    from {
      ${({ direction }) => direction}: 0;
    }

    to {
      ${({ direction }) => direction}: ${number * 32}px;
    }
  }

  @keyframes ${({ direction }) => direction + number} {
    from {
      ${({ direction }) => direction}: 0;
    }

    to {
      ${({ direction }) => direction}: ${number * 32}px;
    }
  }

  @keyframes ${({ direction }) => direction + number} {
    from {
      ${({ direction }) => direction}: 0;
    }

    to {
      ${({ direction }) => direction}: ${number * 32}px;
    }
  } */
`;

export const Circle = styled.span`
  border-radius: 50%;
  position: absolute;
  top: 0;
  ${size};
  transition: all 0.3s;
  pointer-events: none;

  &:nth-child(1) {
    ${drawCircle(1, 1)}
  }

  &:nth-child(2) {
    ${drawCircle(2, 0.6)}
  }

  &:nth-child(3) {
    ${drawCircle(3, 0.2)}
  }
`;

export const WorkButtonContainer = styled(Flex)`
  position: relative;
  ${size};
  transition: ${({ direction }) => direction} 1s
    cubic-bezier(0.14, 0.48, 0, 0.96);

  &:hover ${Circle}, & ${Circle}.hover {
    ${({ direction }) => direction}: 0!important;
  }

  &:hover ${Label} {
    transform: rotate(
      ${({ direction }) => (direction === "right" ? 15 : -15)}deg
    );
  }
`;

export const WorkBtn = styled(Flex)`
  justify-content: center;
  align-items: center;
  background: white;
  cursor: pointer;
  z-index: 1;
  ${size};  
`;

export const FlyPath = styled.div`
  ${size};
`;
