import styled from "styled-components";

export const Div = styled.div`
  z-index: 10001;
  position: fixed;
  opacity: 0.8;
  right: 2px;
  background: red;
  background-color: rgba(70, 71, 111, 0.8);
  border-radius: 8px;
  border: 2px solid transparent;
  width: 4px;

  height: ${({ height = 100 }) => height}px;

  transition-property: transform opacity;
  transition-duration: 0.1s;

  @media(max-width: 1024px) {
    display: none;
  }
`;
