import React from "react";
import useDevice from "src/hooks/useDevice";
import { Hr } from "src/styles/components";
import { TermAndPolicy } from "./components/termAndPolicy";
import { Desktop } from "./components/desktop";
import { Tablet } from "./components/tablet";
import { Mobile } from "./components/mobile";

import { FooterWrapper, SizeWrapper, BlackWrapper } from "./components/styled";

export const Footer = ({
  onTop = () => {
    if(window.innerWidth <= 768) {
      const scrollItem = document.querySelector(".smooth-wrapper");
      scrollItem.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      window.scroll({ top: 0, behavior: "smooth" });
    }
  },
}) => {
  return (
    <BlackWrapper>
      <SizeWrapper>
        <FooterWrapper>
          <Mobile />
          <Tablet />
          <Desktop />
          <Hr />
          <TermAndPolicy onTop={onTop} />
        </FooterWrapper>
      </SizeWrapper>
    </BlackWrapper>
  );
};
