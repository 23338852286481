import React from "react";

import { LoaderWrapper, LoaderAnimated } from "./styled";

export const Loader = () => {
  return (
    <LoaderWrapper>
      <style
        dangerouslySetInnerHTML={{
          __html: `body {overflow: hidden}`,
        }}
      />
      <LoaderAnimated>
        <div />
        <div />
        <div />
        <div />
      </LoaderAnimated>
    </LoaderWrapper>
  );
};
