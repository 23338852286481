import React from "react";

import LogoIcon from "src/assets/icons/GreyApes.com.svg";
import SocialShare from "components/socialShare";
import { theme } from "src/styles/global";
import { Flex } from "src/styles/components";

import {
  Container,
  LogoContainer,
  Text,
  SCLink,
  AddressContainer,
  LinkContainer,
  IsDesktop,
  TopButtonWrapper,
} from "./styled";
import ArrowIcon from "assets/icons/arrow-circle.svg";

export const Desktop = () => {
  return (
    <IsDesktop>
      <Container>
        <LogoContainer>
          <LogoIcon />
        </LogoContainer>
        <div />
        <AddressContainer direction="column" justify="space-between">
          <Text>
            7240 Waverly St. <br />
            Office 212 <br />
            Ukraine, Zhytomir H2R 2Y8
          </Text>
          <SocialShare isGo />
        </AddressContainer>
        <LinkContainer direction="column" gap="20px">
          <SCLink to="/#projects">Projects</SCLink>
          <SCLink to="/#services">Services</SCLink>
          <SCLink to="/#contact-us">Contact us</SCLink>
        </LinkContainer>
        <LinkContainer direction="column" gap="20px">
          <SCLink to={theme.links.instagram} target="_blank">
            Instagram
          </SCLink>
          <SCLink to={theme.links.facebook} target="_blank">
            Facebook
          </SCLink>
          <SCLink to={theme.links.behance} target="_blank">
            Behance
          </SCLink>
          <SCLink to={theme.links.dribbble} target="_blank">
            Dribbble
          </SCLink>
          <SCLink to={theme.links.telegram} target="_blank">
            Telegram
          </SCLink>
        </LinkContainer>
        <AddressContainer
          direction="column"
          justify="space-between"
          align="flex-end"
        >
          <SCLink to="mailto:luciano.nonnis@greyapes.com">luciano.nonnis@greyapes.com</SCLink>
          <TopButtonWrapper
            onMouseDown={() => window.scroll({ top: 0, behavior: "smooth" })}
          >
            <ArrowIcon />
          </TopButtonWrapper>
        </AddressContainer>
      </Container>
    </IsDesktop>
  );
};
