import React from "react";

import { InViewport } from "../inViewport";
import { Title } from "../typography/styled";
import { TextAnimationWrapper } from "./styled";

export const AnimatedText = ({ textForAnimate, defaultDelay = 0.3, ...props }) =>
  textForAnimate.map((text, index) => (
    <TextAnimationWrapper key={`key${index}`}>
      <InViewport
        key={`animate-text-${index}`}
        animationDelay={`${index * 0.1 + defaultDelay}s`}
        animateText
        {...props}
      >
        <Title>{text}</Title>
      </InViewport>
    </TextAnimationWrapper>
  ));
