import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  CloseBtn,
  ContextContainer,
  ControlRadio,
  FormContent,
  FormLine,
  FormTitleContainer,
  ModalContainer,
} from "./contactUsStyled";
import CloseIcon from "src/assets/icons/close.svg";
import { Flex, H1, Text } from "src/styles/components";
import ContactUsSubmit from "src/components/contactUsSubmit/contactUsSubmit";
import { SCInput } from "src/components/common/input/inputStyled";
import { theme } from "src/styles/global";
import useDevice from "src/hooks/useDevice";
import { Loader } from "src/components/loader/loader";
import { sendMessage } from "api";

const INTERESTING_DATA = [
  {
    label: "Download brief",
    slug: "download-brief",
  },
  {
    label: "Logo",
    slug: "logo",
  },
  {
    label: "Branding",
    slug: "branding",
  },
  {
    label: "UI/UX",
    slug: "ui-ux",
  },
  {
    label: "Graphic",
    slug: "graphic",
  },
  {
    label: "Animation",
    slug: "animation",
  },
  {
    label: "E-Commerce",
    slug: "e-commerce",
  },
];

const BUDGET_DATA = [
  {
    label: "$5K",
    slug: "5",
  },
  {
    label: "$5K-$10K",
    slug: "5-10",
  },
  {
    label: "$10K-$20K",
    slug: "10-20",
  },
  {
    label: "$20K",
    slug: "20",
  },
];

const ContactUs = ({ isOpen = true, onClose }) => {
  const isTablet = useDevice(theme.device.tablet);
  const [interesting, setInteresting] = useState(INTERESTING_DATA[0].slug);
  const [budget, setBudget] = useState(BUDGET_DATA[0].slug);
  const [isFetch, setIsFetch] = useState(false);
  const { handleSubmit, register, reset } = useForm();

  const onSubmit = async (data) => {
    setIsFetch(true);

    try {
      await sendMessage(
        JSON.stringify({ ...data, interesting, budget })
      );
    } catch {}

    setInteresting(null);
    setBudget(null);
    reset();

    setTimeout(() => {
      setIsFetch(false);
      onClose();
    }, 5000);
    if (typeof window !== "undefined") {
      window.dataLayer.push({ event: "SEND_CONTACT_US" });
    }
  };

  const onChangeInteresting = (slug) => () => {
    setInteresting(slug);
  };

  const onChangeBudget = (slug) => () => {
    setBudget(slug);
  };

  return (
    <ModalContainer isOpen={isOpen}>
      <CloseBtn onClick={onClose}>
        <CloseIcon />
      </CloseBtn>
      {isFetch ? (
        <Loader></Loader>
      ) : (
        <ContextContainer>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormContent>
              <FormTitleContainer justify="space-between">
                <H1>LEAVE YOUR CONTACTS AND WE WILL CONTACT YOU</H1>
                {!isTablet && <ContactUsSubmit isOpen={isOpen} type="submit" />}
              </FormTitleContainer>
              <Flex direction="column" flex={1}>
                <Text size={18} weight={600} color={theme.colors.white} mb={2}>
                  INTERESTED IN
                </Text>
                <Flex wrap="wrap" gap="20px">
                  {INTERESTING_DATA.map(({ slug, label }) => (
                    <ControlRadio
                      key={slug}
                      type="button"
                      size={16}
                      active={interesting === slug}
                      onClick={onChangeInteresting(slug)}
                    >
                      {label}
                    </ControlRadio>
                  ))}
                </Flex>
                <FormLine mt={5} mb={2.5}>
                  <SCInput
                    placeholder="Name"
                    type="text"
                    {...register("name", { required: true })}
                  />
                  <SCInput
                    placeholder="Email"
                    type="text"
                    {...register("email", { required: true })}
                  />
                </FormLine>
                <SCInput
                  placeholder="Company"
                  type="text"
                  {...register("company", { required: true })}
                />
                <Text
                  size={18}
                  weight={600}
                  color={theme.colors.white}
                  mt={4}
                  mb={2}
                >
                  APPROXIMATE BUDGET
                </Text>
                <Flex wrap="wrap" gap="20px">
                  {BUDGET_DATA.map(({ slug, label }) => (
                    <ControlRadio
                      key={slug}
                      type="button"
                      size={16}
                      active={budget === slug}
                      onClick={onChangeBudget(slug)}
                    >
                      {label}
                    </ControlRadio>
                  ))}
                </Flex>
              </Flex>
              {isTablet && <ContactUsSubmit isOpen={isOpen} type="submit" />}
            </FormContent>
          </form>
        </ContextContainer>
      )}
    </ModalContainer>
  );
};

export default ContactUs;
