import React, { useEffect, useState, useRef } from "react";

import {
  HeaderContentWrapper,
  HeaderMainWrapper,
  SCLink,
  SCContactUs,
  SCNav,
  FooterWrapper,
} from "./styled";
import LogoIcon from "src/assets/icons/GreyApes.com.svg";
import BurgerMenu from "src/components/common/burgerMenu/burgerMenu";
import { Footer } from "components/footer";
import { links } from "./const";

const Header = ({ onOpenContactUs }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHomePage, setIsHomePage] = useState(true);
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);

  const containerRef = useRef(null);
  const isMount = useRef(false);
  const isOpenRef = useRef(false);

  const [{ isShow, isToTop, y }, setDirection] = useState({
    isToTop: false,
    isShow: true,
    y: 0,
  });

  const toggleMenu = () => setIsOpen((f) => !f);

  useEffect(() => {
    isOpenRef.current = isOpen;
  }, [isOpen]);

  useEffect(() => {
    const element =
      typeof window !== "undefined" && window.innerWidth <= 640
        ? document.querySelector(".smooth-wrapper")
        : document;

    isMount.current = true;
    const onScroll = (e) => {
      if (isOpenRef.current) return;
      const { scrollTop } =
        typeof window !== "undefined" && window.innerWidth <= 640
          ? element
          : document.documentElement;

      setDirection(({ y }) => ({
        y: scrollTop,
        isToTop: y > scrollTop,
        isShow: scrollTop < 100,
      }));
    };

    if (window.location.pathname !== "/") {
      setIsHomePage(false);
      setShowBurgerMenu(true);
    } else {
      setTimeout(() => setShowBurgerMenu(true), 2500);
    }

    element.addEventListener("scroll", onScroll);
    return () => element.removeEventListener("scroll", onScroll);
  }, []);

  const pathToLinkLogo =
    typeof window !== "undefined" && window.location.pathname === "/"
      ? "#top"
      : "/";

  return (
    <HeaderMainWrapper
      className={!isToTop && !isShow ? "fadeOut" : "fadeDown"}
      style={{
        animationDuration: isMount.current ? "1s" : "0s",
        background:
          (isToTop && y > window.innerHeight) || isOpen
            ? "rgba(10, 11, 13)"
            : "rgba(10, 11, 13, 0)",

        ...(isOpen ? { height: "100vh" } : {}),
      }}
    >
      <HeaderContentWrapper>
        <SCLink
          as="a"
          href={pathToLinkLogo}
          delay={isHomePage && 12.5}
          className="no-padding"
        >
          <LogoIcon />
        </SCLink>
        <SCNav isOpen={isOpen} ref={containerRef}>
          {links.map(({ title, href }, i) => (
            <SCLink
              to={href}
              key={title}
              href={href}
              delay={isHomePage ? i + 13.5 : i + 1}
            >
              <span
                onClick={() => {
                  if (
                    href.includes("#") &&
                    typeof window !== "undefined" &&
                    window.innerWidth < 769
                  ) {
                    setIsOpen(false);
                    const scrollItem = document.querySelector(".smooth-wrapper");
                    scrollItem
                      .scrollTo({
                        top: document
                          .querySelector(href.replace("/", ""))
                          .getBoundingClientRect().top + scrollItem.scrollTop,
                        behavior: "smooth",
                      });
                  }
                }}
              >
                {title}
              </span>
            </SCLink>
          ))}
          {isOpen && (
            <FooterWrapper>
              <Footer
                onTop={() =>
                  containerRef.current.scroll({ top: 0, behavior: "smooth" })
                }
              />
            </FooterWrapper>
          )}
        </SCNav>
        {showBurgerMenu && <BurgerMenu onClick={toggleMenu} isOpen={isOpen} />}
        <SCContactUs onClick={onOpenContactUs} delay={isHomePage ? 18.5 : 6}>
          Contact us
        </SCContactUs>
      </HeaderContentWrapper>
    </HeaderMainWrapper>
  );
};

export default Header;
