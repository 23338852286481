import React, { useState, useEffect, useRef, useCallback } from "react";
import { smoothScroll } from "./helpers";



export const GsapSmoothScroll = ({ children, isOpen }) => {
  const smoothWrapperRef = useRef();
  const contentWrapperRef = useRef();
  const scrollRef = useRef(isOpen);
  const [isDesktop, setIsDesktop] = useState(
    typeof window !== "undefined" && window.innerWidth > 640
  );

  useEffect(() => {
    scrollRef.current = isOpen;
  }, [isOpen]);

  useEffect(() => {
    const isDesktop = typeof window !== "undefined" && window.innerWidth > 640;
    if (isDesktop) {
      smoothScroll(
        contentWrapperRef.current,
        smoothWrapperRef.current,
        1,
        scrollRef
      );
    }
    setIsDesktop(isDesktop);
  }, [smoothWrapperRef, contentWrapperRef]);



  return (
    <>
      <section
        className="smooth-wrapper"
        ref={smoothWrapperRef}
        style={
          isDesktop
            ? {
                width: "100vw",
                height: "100vh",
                overflowX: "hidden",
                position: "fixed",
              }
            : {
                width: "100vw",
                height: "100vh",
                overflowX: "hidden",
                overflowY: "scroll",
              }
        }
      >
        <section className="content-wrapper" ref={contentWrapperRef}>
          {children}
        </section>
      </section>
    </>
  );
};
