import React from "react";
import { ContainerProjectBanner, ProjectImage } from "./styled";
import { Title } from "components/typography";
import ProjectBannerImage from "src/assets/images/frog.png";
import { AnimatedText } from "components/animatedText/animatedText";
import { InViewport } from "components/inViewport";
import useDevice from "hooks/useDevice";
import { theme } from "styles/global";

export const ProjectBanner = () => {
  const isMobile = useDevice(theme.screenSize.mobile);

  return (
    <ContainerProjectBanner>
      <ProjectImage src={ProjectBannerImage} />
      <Title
        title={
          <AnimatedText
            defaultDelay={0.3}
            textForAnimate={isMobile ?
              [
                "We push brands",
                "to their",
                "absolute",
                "potential by",
                "creating",
                "the perfect",
                "design"
              ]
              : [
              "We push brands to",
              "their absolute",
              "potential by creating",
              "the perfect design",
            ]}
          />
        }
        subTitle={
          <InViewport animationDelay="0.6s">
            ur goal of making a great positive impact with every project is what
            drives us. That's why
            <br />
            we specialise in working with brands promoting environmental and
            socially responsible
            <br />
            products, practices, and values.
          </InViewport>
        }
        gap={60}
      />
    </ContainerProjectBanner>
  );
};
