import styled from "styled-components";
import {theme} from "src/styles/global";

export const SCInput = styled.input`
  border: none;
  border-bottom: 1px solid ${theme.colors.scarpaFlow};
  background: transparent;
  padding: 14px 0;
  border-radius: 0;
  outline: none;

  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: ${theme.colors.white};
  
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active{
    -webkit-text-fill-color: ${theme.colors.white} !important;
    -webkit-box-shadow: 0 0 0 30px black inset !important;
  } 
`;
