import React, { useEffect, useState, useRef, useMemo } from "react";
import { SocialShare } from "src/components/socialShare";
import { FlyButton } from "src/components/flyButton";
import { Flex } from "styles/components";
import {
  ContainerTopHomeBanner,
  ContentContainer,
  Label,
  IconArrow,
  SocialContainer,
  BrandContainer,
  Video,
  FullWidthLabel,
  LoaderHomeBanner,
} from "./styled";
import BannerVideo0 from "src/assets/videos/home0.webm";
import BannerVideo1 from "src/assets/videos/home1.webm";
import { InViewport } from "src/components/inViewport";
import { AnimatedText } from "../animatedText/animatedText";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Wave } from "components/wave";
import { theme } from "styles/global";
import Loader from "src/assets/images/loader.png";

const topHomeBannerText = ["We deliver", "Design", "Perfection"];

export const TopHomeBanner = () => {
  const isMobile = typeof window !== "undefined" && window.innerWidth <= 640;
  const [mount, setMount] = useState(false);
  const [isLoop, setLoop] = useState(false);
  const [loader, setLoader] = useState(!isMobile);

  const mountRefOne = useRef(true);
  const ref = useRef();

  useEffect(() => {
    if (mount && mountRefOne.current && !isMobile) {
      ScrollTrigger.create({
        scroller: ".content-wrapper",
        trigger: '.banner',
        start: "top top",
        pin: true,
        pinSpacing: false,
        ease: "none",
      });
      mountRefOne.current = false;
      setTimeout(() => setLoader(false), 350);
    } else {
      setMount(true);
    }
  }, [mount]);

  useEffect(() => {
    return () => console.log("render");
  }, []);

  const handleClickFlyButton = () => {
    if (window.innerWidth <= 768) {
      const scrollItem = document.querySelector(".smooth-wrapper");
      scrollItem.scrollTo({
        top:
          document.querySelector("#projects").getBoundingClientRect().top +
          scrollItem.scrollTop,
        behavior: "smooth",
      });
    } else {
      document.querySelector("[href='/#projects']")?.click();
    }
  };

  return (
    <>
      <ContainerTopHomeBanner key={'rest'} ref={ref} className="banner">
        <ContentContainer>
          <Flex direction={"column"}>
            <AnimatedText
              defaultDelay={3}
              key={"banner-text"}
              textForAnimate={topHomeBannerText}
            />
          </Flex>
          <SocialContainer>
            <InViewport animationDelay="3.5s">
              <FullWidthLabel color="#fff">
                Check out our social media
              </FullWidthLabel>
            </InViewport>
            <InViewport animationDelay="3.65s">
              <SocialShare isGo color="#FBFAFF" colorHover="#555" />
            </InViewport>
          </SocialContainer>
        </ContentContainer>
        <Video
          src={BannerVideo0}
          preload="preload"
          autoPlay
          muted
          played
          loop={false}
          onEnded={() => {
            setLoop(true);
          }}
          style={{
            opacity: isLoop ? 0 : 1,
            display: isLoop ? "none" : "block",
          }}
        />
        <Video
          src={BannerVideo1}
          preload="preload"
          autoPlay
          muted
          played={isLoop}
          loop={isLoop}
          style={{
            opacity: isLoop ? 1 : 0,
            display: isLoop ? "block" : "none",
          }}
        />
        <ContentContainer align="flex-end">
          <InViewport animationDelay="4s">
            <BrandContainer align="center">
              <IconArrow />
              <Label size={18} bottom={0} color="white">
                We craft valuable experiences that push brands forward.
              </Label>
            </BrandContainer>
          </InViewport>
          <InViewport animationDelay="4.5s">
            <FlyButton
              direction={isMobile ? "left" : "right"}
              delay={5200}
              label="WORK"
              onClick={handleClickFlyButton}
            />
          </InViewport>
        </ContentContainer>
      </ContainerTopHomeBanner>
      <Wave />
      <LoaderHomeBanner
        style={{
          display: loader && !isMobile ? "flex" : "none",
        }}
      >
        <img src={Loader} className="loader-animation" />
      </LoaderHomeBanner>
    </>
  );
};
