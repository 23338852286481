import styled from "styled-components";

export const Canvas = styled.canvas``;

export const CursorBLock = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
  box-shadow: 0 0 300px 300px rgb(41, 40, 67, 36%);
  transition: all 0.9s ease-out;

  @media(max-width: 1024px) {
    display: none;
  }
`;
