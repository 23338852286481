export const links = [
  {
    href: "/#about",
    title: "About",
  },
  {
    href: "/#services",
    title: "Services",
  },
  {
    href: "/projects",
    title: "Projects",
  },
  {
    href: "/#clients",
    title: "Clients",
  },
  {
    href: "/#contact-us",
    title: "Contact Us",
  },
];
