import React, { useEffect, useState, useRef } from "react";
import { WaveComponent } from "./styled";
import { gsap } from "gsap";

export const Wave = () => {
  const ref = useRef();
  // TODO wave animation
  const [mount, setMount] = useState(false);
  useEffect(() => {
    let id;
    if (mount && typeof window !== 'undefined' && window.innerWidth > 640) {
      gsap.to(ref.current, {
        scrollTrigger: {
          scroller: ".content-wrapper",
          trigger: ".wave",
          start: "top bottom",
          scrub: true,
        },
        scaleY: 5,
        translateY: 50,
        ease: "custom",
      });
    } else {
      setMount(true);
    }

    return () => {
      clearTimeout(id);
    };
  }, [mount]);

  return <WaveComponent ref={ref} className="wave"></WaveComponent>;
};
