import styled, { css } from "styled-components";
import { theme } from "src/styles/global";

const size = css`
  width: 14vw;
  height: 14vw;
  border-radius: 50%;
  min-width: 140px;
  min-height: 140px;
  max-width: 200px;
  max-height: 200px;

  @media (${theme.device.laptop}) {
    width: 18vw;
    height: 18vw;
  }
`;

export const SendBtn = styled.button`
  background: ${theme.colors.white};
  padding: 0;
  border: none;
  cursor: pointer;
  ${size};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  transition: transform 0.3s;
  transform: rotate(-20deg);

  @media (${theme.device.mobile}) {
    svg {
      height: 13%;
    }    
  }
`;

export const BtnLabel = styled.span`
  color: ${theme.colors.black};
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3;
  text-align: center;
  text-transform: uppercase;
  max-width: 100px;
  margin-bottom: 12px;

  @media (${theme.device.tablet}) {
    font-size: 16px;
  }
`;

const drawCircle = (number, opacity) => css`
  border: 1px solid rgba(255, 255, 255, ${opacity});
  left: ${number * 48}px;
  transition: left 0.3s;
  ${size};
  
  &.start {
    left: 0;
  }

  @media (${theme.device.tablet}) {
    left: ${number * 32}px;
  }
`;

export const Circle = styled.span`
  border-radius: 50%;
  width: 200px;
  height: 200px;
  position: absolute;
  top: 0;
  transition: left 0.3s;
  pointer-events: none;

  &:nth-child(1) {
    ${drawCircle(1, 1)}
  }

  &:nth-child(2) {
    ${drawCircle(2, 0.6)}
  }

  &:nth-child(3) {
    ${drawCircle(3, 0.2)}
  }

  @media (${theme.device.tablet}) {
    width: 150px;
    height: 150px;
  }
`;

export const Container = styled.div`
  position: relative;
  ${size};

  &:hover ${Circle} {
    left: 0;
  }

  &:hover ${SendBtn} {
    transform: rotate(20deg);
  }
`;
