import styled, { css } from "styled-components";
import { theme } from "src/styles/global";

const animate = css`
  & > span:nth-child(1) {
    transform: rotate(45deg);
    width: 120%;
    top: 13px;
  }
  & > span:nth-child(2) {
    opacity: 0;
  }
  & > span:nth-child(3) {
    transform: rotate(-45deg);
    width: 120%;
    top: 13px;
  }
`;

export const SCBurgerBtn = styled.button`
  background: none;
  border: none;
  display: none;
  position: relative;
  width: 30px;
  height: 26px;
  cursor: pointer;

  & > span {
    background-color: #fff;
    position: absolute;
    transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
    width: 100%;
    height: 3px;
    transition-duration: 300ms;
  }

  & > span:nth-child(1) {
    top: 0;
    left: 0;
  }
  & > span:nth-child(2) {
    width: 60%;
    top: 11px;
    right: 0;
    opacity: 1;
  }
  & > span:nth-child(3) {
    bottom: 0;
    left: 0;
  }

  ${({ isOpen }) => isOpen && `${animate}`};
  display: ${({ isOpen }) => (isOpen ? "" : "")};

  @media (${theme.device.tablet}) {
    display: block;
  }
`;
