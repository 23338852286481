import React, { useEffect, useState, useRef } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { ActionButton, ShareLikeWrapper } from "./styled";
import { theme } from "src/styles/global";
import Like from "assets/icons/like.svg";
import Share from "assets/icons/share.svg";
import ShareLink from "assets/icons/share-link.svg";
import Facebook from "src/assets/icons/facebook.svg";
import Linkedin from "src/assets/icons/linkedin.svg";
import Twitter from "src/assets/icons/twitter.svg";

export const ShareSocial = ({
  like,
  setLikeHandler,
  totalLike,
  isScrollShare = false,
  className = "",
}) => {
  const [mount, setMount] = useState(false);
  const [shareSocial, setShareSocial] = useState(false);
  const ref = useRef();
  const encodeURL = encodeURIComponent(theme.links.shareLink);

  const socialShareData = [
    {
      element: <Twitter />,
      path: `https://twitter.com/intent/tweet?url=${encodeURL}`,
    },
    {
      element: <Facebook />,
      path: `https://www.facebook.com/sharer.php?u=${encodeURL}`,
    },
    {
      element: <Linkedin />,
      path: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURL}`,
    },
  ];

  useEffect(() => {
    if (mount && isScrollShare) {
      const { top: initTopPos } = document
        .querySelector(".share-link")
        .getBoundingClientRect();

      let adjustmentPosition = initTopPos / window.innerHeight;
      window.addEventListener(
        "resize",
        () => (adjustmentPosition = initTopPos / window.innerHeight)
      );

      ScrollTrigger.create({
        scroller: ".content-wrapper",
        trigger: ".action-btn",
        start: "top bottom",
        end: "bottom bottom+=50px",
        onUpdate: ({ progress }) => {
          if (ref.current && progress < 1) {
            const { top } = document
              .querySelector(".share-link")
              .getBoundingClientRect();
            ref.current.style.transform = `translateY(${
              -top + adjustmentPosition * window.innerHeight
            }px)`;
          }
        },
      });
    } else {
      setMount(true);
    }
  }, [mount, isScrollShare]);

  useEffect(() => {
    const bodyEl = document.querySelector("body");
    const handleCloseShareSocial = () => setShareSocial(false);

    bodyEl.addEventListener("click", handleCloseShareSocial);
    return () => bodyEl.removeEventListener("click", handleCloseShareSocial);
  }, []);

  const handleClickShareSocial = (e) => e.stopPropagation();

  const copyToClipboard = () => {
    const textField = document.createElement("textarea");
    textField.innerText = theme.links.shareLink;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  };

  const handleOpenShareSocial = (e) => {
    e.stopPropagation();
    copyToClipboard();
    setShareSocial(!shareSocial);
  };

  return (
    <ShareLikeWrapper ref={ref} className={className}>
      <ActionButton
        className={shareSocial ? "social share" : "social"}
        onClick={handleOpenShareSocial}
      >
        {socialShareData.map(({ element, path }) => (
          <a
            href={path}
            onClick={handleClickShareSocial}
            target="_blank"
            key={`key-${path}`}
          >
            {element}
          </a>
        ))}
        {shareSocial ? <ShareLink /> : <Share />}
      </ActionButton>
      <ActionButton
        className={like && "like"}
        content={totalLike}
        data-social
        onClick={setLikeHandler}
      >
        <Like />
      </ActionButton>
    </ShareLikeWrapper>
  );
};
