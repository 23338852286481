import browserSignature from "browser-signature";
import { useCallback, useEffect, useState } from "react";

let signature;
try {
  signature = getLocalData("browserSignature") || browserSignature();
  setLocalData("browserSignature", signature);
} catch {}

function getLocalData(key) {
  try {
    return localStorage.getItem(key);
  } catch {}
}

function setLocalData(key, value) {
  try {
    localStorage.setItem(key, value);
  } catch {}
}

export const useView = (slag) => {
  const [view, setView] = useState(Number(getLocalData("view")) || 1);

  useEffect(() => {
    fetch(
      `${process.env.GATSBY_API_URL}/view?signature=${encodeURIComponent(
        signature
      )}&slag=${encodeURIComponent(slag)}`
    )
      .then((res) => res.json())
      .then(({ total }) => {
        setLocalData("view", total);
        setView(total);
      })
      .catch((e) => e);
  }, [slag]);

  return { view };
};

export const useLike = (slag) => {
  const [like, setLike] = useState(Number(getLocalData("like")) || 1);
  const [isLike, setIsLike] = useState(!!getLocalData("isLike"));

  useEffect(() => {
    fetch(
      `${process.env.GATSBY_API_URL}/is-like?signature=${encodeURIComponent(
        signature
      )}&slag=${encodeURIComponent(slag)}`
    )
      .then((res) => res.json())
      .then(({ total, isLike }) => {
        setLike(total);
        setIsLike(isLike);
      })
      .catch((e) => e);
  }, [slag]);

  const setLikeHandler = useCallback(() => {
    if (isLike) return;
    setLike((_) => _ + 1);
    setIsLike(true);

    fetch(
      `${process.env.GATSBY_API_URL}/like?signature=${encodeURIComponent(
        signature
      )}&slag=${encodeURIComponent(slag)}`,
      {
        method: "post",
      }
    )
      .then((res) => res.json())
      .then(({}) => {})
      .catch((e) => e);
  }, [isLike]);

  useEffect(() => {
    setLocalData("like", like);
    setLocalData("isLike", isLike ? "1" : "");
  }, [like, isLike]);

  return { like, isLike, setLikeHandler };
};

export async function sendMessage(body) {
  return fetch(`${process.env.GATSBY_API_URL}/message`, {
    method: "post",
    body,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).catch((e) => e);
}
