import React, { useEffect, useRef, useState, useMemo } from "react";


export const InViewport = ({
  offset = 0,
  animationDelay = 1,
  animateText = false,
  animationClass,
  unique = "",
  ...props
}) => {
  const [isIntersecting, setIntersecting] = useState(false);
  const ref = useRef();
  const lastValue = useRef();

  useEffect(() => {
    const element =
      typeof window !== "undefined" && window.innerWidth <= 640
        ? document.querySelector(".smooth-wrapper")
        : document;

    let once = false;
    const onScroll = () => {
      if (isIntersecting || once) return;
      if (!ref.current) {
        return;
      }
      const top = ref.current?.getBoundingClientRect().top;
      once = top + offset >= 0 && top - offset <= window.innerHeight;

      setIntersecting(once);
    };

    onScroll();
    element.addEventListener("scroll", onScroll);
    return () => element.removeEventListener("scroll", onScroll);
  }, []);

 

  const handleClassForViewPort = useMemo(() => {
    if (lastValue.current) return lastValue.current;
    if (isIntersecting && animateText) lastValue.current = "fadeInText";
    if (animationClass) lastValue.current = animationClass;
    if (isIntersecting) lastValue.current = "fadeIn";

    return lastValue.current || "hide";
  });

  const style = useMemo(() => {
    return { animationDelay, zIndex: 1 };
  }, []);

  return (
    <div
      ref={ref}
      { ...{ className: handleClassForViewPort, style: style }}
    >
      {props.children}
    </div>
  );
};
