import { AnchorLink } from "gatsby-plugin-anchor-links";
import styled from "styled-components";
import { Flex } from "src/styles/components";
import { theme } from "src/styles/global";
import { layoutSize } from "styles/mixin";

export const BlackWrapper = styled.div`
  background: black;
`;

export const SizeWrapper = styled.div`
  margin: 0 auto;
  box-sizing: border-box;
  ${layoutSize};
`;

export const FooterWrapper = styled.div`
  width: 100%;
  background: black;
`;

export const MobileContainer = styled(Flex)`
  padding: ${({ padding = "75px 0" }) => padding};
`;

export const Container = styled(Flex)`
  padding: ${({ padding = "75px 0" }) => padding};
  justify-content: space-between;
  align-items: ${({ align = "flex-start" }) => align};
`;

export const LinkContainer = styled(Flex)`
  justify-content: space-between;
`;

export const AddressContainer = styled(Flex)`
  justify-content: space-between;
  align-self: stretch;
`;

export const LogoContainer = styled.div`
  * {
    fill: ${theme.colors.scarpaFlow};
  }

  @media (${({ lvl = theme.device.desktop }) => lvl}) {
    display: none;
  }
`;

export const Text = styled.span`
  color: ${theme.colors.scarpaFlow};
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  text-align: ${({ align }) => align};
`;

export const SCLink = styled(AnchorLink)`
  color: ${theme.colors.scarpaFlow};
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  line-height: 150%;

  &:hover {
    color: ${theme.colors.white};
  }
`;

export const Gap = styled.div`
  height: ${({ height = 30 }) => height}px;
`;

export const IsDesktop = styled.section`
  display: none;
  @media (min-width: 769px) {
    display: block;
  }
`;
export const IsTablet = styled.section`
  display: none;

  @media (max-width: 768px) {
    display: block;
  }

  @media (max-width: 640px) {
    display: none;
  }
`;
export const IsMobile = styled.section`
  display: none;

  @media (max-width: 640px) {
    display: block;
  }
`;

export const TopButtonWrapper = styled.button`
  background: none;
  border: none;
  position: relative;
  width: 81px;
  height: 81px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  * {
    fill: ${theme.colors.scarpaFlow};
  }
`;
