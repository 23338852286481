import React, { useState, useEffect } from "react";
import Header from "../header/header";
import { Footer } from "components/footer";
import { GlobalStyle } from "src/styles/globalStyles";
import CookiesWarning from "../cookiesWarning/cookiesWarning";
import { MainContainer } from "./styled";
import ContactUs from "../contactUs/contactUs";
import { FooterBanner } from "src/components/banner";

import { GsapSmoothScroll } from "components/gsapSmoothScroll";
import { Cursor } from "components/cursor";
import { CustomScroll } from "components/customScroll";

const Layout = ({ children }) => {
  const [isContactUs, setIsContactUs] = useState("");

  const onCloseContactUs = () => {
    setIsContactUs(false);
  };

  const onOpenContactUs = () => {
    setIsContactUs(true);
  };

  useEffect(() => {
    if (isContactUs && typeof window !== "undefined") {
      window.dataLayer.push({ event: "OPEN_CONTACT_US" });
    }
  }, [isContactUs]);

  return (
    <>
      <CustomScroll />
      <Cursor />
      <GlobalStyle />
      <CookiesWarning />
      <Header onOpenContactUs={onOpenContactUs} />
      <GsapSmoothScroll isOpen={isContactUs}>
        <MainContainer id="mainContainer">
          <main data-v="2" style={{ flex: 1, overflowX: "" }}>{children}</main>
          <FooterBanner onOpenContactUs={onOpenContactUs} />
          <Footer />
        </MainContainer>
      </GsapSmoothScroll>
      <ContactUs isOpen={isContactUs} onClose={onCloseContactUs} />
    </>
  );
};

export default Layout;
