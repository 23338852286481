import styled from "styled-components";
import { theme } from "src/styles/global";

export const CookiesContainer = styled.div`
  z-index: 1001;
  display: ${({ isNone = true }) => (isNone ? "none" : "flex")};
  justify-content: center;
  width: 100vw;
  min-height: 150px;
  padding: 35px;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  background: ${theme.colors.charade};

  @media (${theme.device.tablet}) {
    padding: 20px;
  }
`;

export const CookiesContent = styled.div`
  max-width: 1520px;
  width: 100%;

  @media (${theme.device.tablet}) {
    flex-direction: column;

    & > div {
      margin-bottom: 16px;
    }
  }
`;

export const TextStyle = styled.p`
  flex: 1;
  max-width: 730px;
`;

export const AcceptStyle = styled.p`
  padding-left: 7.75rem;
  padding-right: 7.75rem;

  @media (${theme.device.tablet}) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
`;
