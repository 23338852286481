import React, { useEffect, useRef } from "react";
import { CursorBLock } from "./styled";

export const Cursor = () => {
  const ref = useRef();

  useEffect(() => {
    let id;
    function onMove(e) {
      if (ref.current && Date.now() % 4 === 0) {
        ref.current.style.transform = `translate(${e.clientX}px,${e.clientY}px)`;
        ref.current.style.opacity = 1;
      }

      clearTimeout(id);
      id = setTimeout(() => {
        if (ref.current) ref.current.style.opacity = 0;
      }, 500);
    }

    window.addEventListener("mousemove", onMove);
    return () => {
      window.removeEventListener("mousemove", onMove);
    };
  }, []);

  return <CursorBLock ref={ref} />;
};
