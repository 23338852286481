import styled, { css, keyframes } from "styled-components";
import { theme } from "src/styles/global";
import { Button, Flex } from "src/styles/components";

const SHOW_RADIUS_VAR = "15vh";
const HIDE_RADIUS_VAR = "20vh";

const show = keyframes`
  0% {
    top: 100vh;
    display: block;
    border-radius: 50% 50% 0 0 / ${SHOW_RADIUS_VAR};
    width: 100vw;
    left: 0;
    padding-left: 0;
    --button-opacity: 0;
  }

  1% {
    top: 100vh;
    display: block;
    border-radius: 50% 50% 0 0 / ${SHOW_RADIUS_VAR};
    width: 120vw;
    left: -10vw;
    padding-left: 10vw;
    --button-opacity: 0;
  }

  90% {
    top: 0;
    display: block;
    border-radius: 50% 50% 0 0 / ${SHOW_RADIUS_VAR};
    width: 120vw;
    left: -10vw;
    padding-left: 10vw;
    --button-opacity: 0;
  }
  
  100% {
    display: block;
    border-radius: 0;
    width: 100vw;
    left: 0;
    padding-left: 0;
    --button-opacity: 1;
  }
`;

const hide = keyframes`
  0% {
    height: 100vh;
    padding-top: 0;
    top: 0;
    width: 100vw;
    left: 0;
    padding-left: 0;
    --button-opacity: 0;
  }

  1% {
    background: radial-gradient(250% ${HIDE_RADIUS_VAR} at 50% 0, transparent, transparent 20%, #000 20%, #000 100%);
    height: 120vh;
    padding-top: 20vh;
    top: -20vh;
    width: 120vw;
    left: -10vw;
    padding-left: 10vw;
    --button-opacity: 0;
  }

  20% {
    background: radial-gradient(250% ${HIDE_RADIUS_VAR} at 50% 0, transparent, transparent 20%, #000 20%, #000 100%);
    height: 100vh;
    padding-top: 0;
    top: 0;
  }

  99% {
    background: radial-gradient(250% ${HIDE_RADIUS_VAR} at 50% 0, transparent, transparent 20%, #000 20%, #000 100%);
    top: 100vh;
    width: 120vw;
    left: -10vw;
    padding-left: 10vw;
  }
  100% {
    background: radial-gradient(0 0 at 50% 0, transparent, transparent 20%, #000 20%, #000 100%);
    top: 100vh;
    width: 100vw;
    left: 0;
    padding-left: 0;
    --button-opacity: 0;
  }
`;

export const ModalContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10002;
  width: 100vw;
  height: 100vh;
  background: ${theme.colors.black};
  overflow-y: auto;
  overflow-x: hidden;
  display: ${({ isOpen }) => (isOpen === "" ? "none" : "block")};
  animation: ${({ isOpen }) => (isOpen ? show : hide)} 0.5s
    cubic-bezier(0.14, 0.48, 0, 0.96) forwards;
`;

export const ContextContainer = styled.div`
  padding: 100px 180px;
  width: 100vw;
  box-sizing: border-box;
  min-height: 100vh;
  display: flex;
  align-items: center;

  @media (${theme.device.laptop}) {
    padding: 80px;
  }

  @media (${theme.device.tablet}) {
    padding: 80px 40px;
  }

  & h1 {
    max-width: 80vw;
  }
`;

export const CloseBtn = styled.button`
  background: none;
  padding: 0;
  border: none;
  cursor: pointer;
  z-index: 102;
  opacity: var(--button-opacity);
  transition: opacity 0.5s linear;
  position: absolute;
  top: 46px;
  right: 46px;
`;

export const FormLine = styled(Flex)`
  gap: 80px;

  @media (${theme.device.laptop}) {
    flex-direction: column;
    gap: 2.5rem;
  }
`;

const activeStyle = css`
  color: ${theme.colors.woodsmoke};
  background: ${theme.colors.magnolia};
`;

export const ControlRadio = styled(Button)`
  ${({ active }) => active && activeStyle};
  padding: 1.25em 34px;

  @media (${theme.device.mobile}) {
    padding: 1em 24px;
  }
`;

export const FormContent = styled(Flex)`
  gap: 60px;

  @media (${theme.device.tablet}) {
    flex-direction: column;
  }
`;

export const FormTitleContainer = styled(Flex)`
  flex-direction: column;
  flex: 1.3;

  @media (${theme.device.tablet}) {
    flex-direction: row;
    flex: 1;
  }
`;
