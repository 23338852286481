import React, { useState, useRef, useEffect } from "react";
import { SocialShare } from "src/components/socialShare";
import { FlyButton } from "src/components/flyButton";
import { InViewport } from "../inViewport";
import { AnimatedText } from "../animatedText/animatedText";
import { Title } from "components/typography";

import {
  ContainerFooterBanner,
  Content,
  ContentRow,
  Gap,
  SocialContainer,
  SubLabel,
  FlyContainerFooter,
  Planet,
  MobilePlanet,
  ButtonContainer,
  AddressDot,
  AddressPulse,
} from "./styled";
import { gsap } from "gsap";

export const FooterBanner = ({ onOpenContactUs }) => {
  const ref = useRef();
  const planetDesktop = useRef();
  const addressRef = useRef();

  const [mount, setMount] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isAddress, setAddress] = useState(false);
  const [isRunAnimation, setRunAnimation] = useState(false);

  useEffect(() => {
    const handleDevice = () => setIsMobile(window.innerWidth <= 640);
    handleDevice();

    window.addEventListener("resize", handleDevice);
    return () => window.removeEventListener("resize", handleDevice);
  }, []);

  useEffect(() => {
    if (mount) {
      let ratioToggleMountAddress = window.innerWidth < 625 ? 0 : 0.8;
      window.addEventListener("resize", () => {
        if (window.innerWidth < 625 && addressRef.current) {
          ratioToggleMountAddress = 0;
          addressRef.current.style.opacity = 1;
        } else {
          ratioToggleMountAddress = 0.8;
        }
      });

      gsap.to(planetDesktop.current, {
        scrollTrigger: {
          scroller: ".content-wrapper",
          trigger: ref.current,
          start: "top bottom",
          end: "bottom bottom",
          scrub: true,
          onUpdate: ({ progress }) => {
            if (!ratioToggleMountAddress) return;
            if (progress > ratioToggleMountAddress) {
              addressRef.current.style.opacity = 1;
            } else {
              addressRef.current.style.opacity = 0;
            }
          },
        },
        scale: 1,
        top: 0,
        onComplete: () => {},
      });
    } else {
      setMount(true);
    }
  }, [mount]);

  useEffect(() => {
    const element =
      typeof window !== "undefined" && window.innerWidth <= 640
        ? document.querySelector(".smooth-wrapper")
        : document;

    const onScroll = () => {
      const top = ref.current?.getBoundingClientRect().top;
      const offset = -window.innerHeight * 0.1;
      setRunAnimation(
        (_) => _ || (top + offset >= 0 && top + offset <= window.innerHeight)
      );
      if (top >= 0 && top <= window.innerHeight) {
        setTimeout(() => setAddress(true), 900);
      }
    };

    element.addEventListener("scroll", onScroll);
    return () => element.removeEventListener("scroll", onScroll);
  });

  return (
    <ContainerFooterBanner id="contact-us" ref={ref}>
      <Planet ref={planetDesktop} className="planet" />
      <AddressDot ref={addressRef} className={isAddress && "showAddress"}>
        <AddressPulse scale={12} delay={2} />
        <AddressPulse scale={12} delay={3} />
        <AddressPulse scale={12} delay={4} />
      </AddressDot>
      <Content>
        <ContentRow className="topRow">
          <Title
            title={
              <AnimatedText
                defaultDelay={0.3}
                textForAnimate={["CONTACT US"]}
              />
            }
            subTitle={
              isMobile ? (
                <InViewport animationDelay="0.3s">
                  We will help you achieve this goal with a well-thought-out
                  design.
                </InViewport>
              ) : (
                <InViewport animationDelay="0.6s">
                  And we will call you shortly.
                </InViewport>
              )
            }
          />
          <InViewport animationDelay="0.9s">
            <SocialContainer align="flex-end">
              <SocialShare color="#C4BFCE" />
              <Gap height={18} />
              <SubLabel size={18}>luciano.nonnis@greyapes.com</SubLabel>
            </SocialContainer>
          </InViewport>
        </ContentRow>
        <MobilePlanet className={isRunAnimation ? "planeFadeInMobile" : ""} />
        <ContentRow className="bottomRow">
          <ButtonContainer>
            <InViewport animationDelay={isMobile ? "0.3s" : "1.2s"}>
              <SubLabel family="Dinish" size={30} bottom={0} isOffHover={true}>
                Leave your contacts and
                <br />
                we will contact you
              </SubLabel>
            </InViewport>
            <InViewport animationDelay={isMobile ? "0.3s" : "1s"}>
              <FlyContainerFooter>
                <FlyButton
                  delay={400}
                  label="Write a message"
                  direction="left"
                  onClick={onOpenContactUs}
                />
              </FlyContainerFooter>
            </InViewport>
          </ButtonContainer>
          <InViewport animationDelay={isMobile ? "0.3s" : "1s"}>
            <SubLabel size={18} isOffHover={true}>
              7240 Waverly St. Office 212
              <br />
              Ukraine, Zhytomir H2R 2Y8
            </SubLabel>
          </InViewport>
        </ContentRow>
      </Content>
    </ContainerFooterBanner>
  );
};
