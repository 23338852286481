import React from "react";

import InstagramIcon from "src/assets/icons/instagram.svg";
import FacebookIcon from "src/assets/icons/facebook.svg";
import TelegramIcon from "src/assets/icons/telegram.svg";
import BehanceIcon from "src/assets/icons/behance.svg";
import DribbleIcon from "src/assets/icons/dribbble.svg";
import { SocialButton, SocialContainer, Gap } from "./styled";
import { theme } from "src/styles/global";

export const SocialShare = ({ isGo, color, colorHover }) => {
  const encodeURL = encodeURIComponent(theme.links.shareLink);

  return (
    <SocialContainer align="space-between">
      <SocialButton
        color={color}
        colorHover={colorHover}
        as="a"
        href={theme.links.instagram}
        target="_blank"
      >
        <InstagramIcon />
      </SocialButton>
      <Gap />
      <SocialButton
        color={color}
        colorHover={colorHover}
        as="a"
        href={
          isGo
            ? theme.links.facebook
            : `https://www.facebook.com/sharer/sharer.php?u=${encodeURL}`
        }
        target="_blank"
      >
        <FacebookIcon />
      </SocialButton>
      <Gap />
      <SocialButton
        color={color}
        colorHover={colorHover}
        as="a"
        href={
          isGo
            ? theme.links.telegram
            : `https://telegram.me/share/url?url=${encodeURL}`
        }
        target="_blank"
      >
        <TelegramIcon />
      </SocialButton>
      <Gap />
      <SocialButton
        color={color}
        colorHover={colorHover}
        as="a"
        href={theme.links.behance}
        target="_blank"
      >
        <BehanceIcon />
      </SocialButton>
      <Gap />
      <SocialButton
        color={color}
        colorHover={colorHover}
        as="a"
        href={theme.links.dribbble}
        target="_blank"
      >
        <DribbleIcon />
      </SocialButton>
    </SocialContainer>
  );
};

export default SocialShare;
