import { useState, useEffect } from "react";
export const isBrowser = () => typeof window !== "undefined";

export default function useDevice(query) {
  const filterQuery = `(${query})`;
  const [isDevice, setIsDevice] = useState(
    isBrowser() ? window?.matchMedia(filterQuery).matches : null
  );

  useEffect(() => {
    if (isBrowser()) {
      const mql = window?.matchMedia(filterQuery);
      mql.onchange = (e) => setIsDevice(e.matches);
    }
  }, [filterQuery]);

  return isDevice;
}

export function usePaddingSize(selector = "#mainContainer") {
  const [size, setSize] = useState(0);

  useEffect(() => {
    function onResize() {
      try {
        const { width } = document
          .querySelector(selector)
          .getBoundingClientRect();
        setSize((window.innerWidth - width) / 2);
      } catch (_) {}
    }

    if (isBrowser()) {
      onResize();
      window.addEventListener("resize", onResize);
    }

    return () => {
      if (isBrowser()) {
        window.removeEventListener("resize", onResize);
      }
    };
  }, []);

  return size;
}
