import styled, { css, keyframes } from "styled-components";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { theme } from "src/styles/global";
import { Button } from "src/styles/components";
import { layoutSize } from "styles/mixin";

const incomingHeaderLink = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
 
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const incomingHeader = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  
  80% {
    opacity: 1;
    transform: scale(1.2);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

export const HeaderMainWrapper = styled.div`
  z-index: 10001;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  position: fixed;
  transition: background-color 0.3s cubic-bezier(0.14, 0.48, 0, 0.96);
`;

export const HeaderContentWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 100px;

  @media (${theme.device.tablet}) {
    justify-content: space-between;
  }

  @media (${theme.device.laptop}) {
    height: 80px;
  }
  ${layoutSize};
`;

export const FooterWrapper = styled.div`
  padding: 0 6.67vw;
  background: black;
  width: 100%;
`;

export const animate = css`
  opacity: 0;
  animation: ${incomingHeaderLink} 1s cubic-bezier(0.14, 0.48, 0, 0.96) forwards;
  animation-delay: ${({ delay = 0 }) => delay * 0.2 + "s"};
`;

export const SCLink = styled(AnchorLink)`
  color: ${theme.colors.graySuit};
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 14px;
  ${animate};

  span {
    transition: all 0.3s;
  }

  &:hover span {
    opacity: 1 !important;
  }

  @media (${theme.device.tablet}) {
    font-family: "Dinish", sans-serif;
    font-weight: 700;
    font-size: 50px;
    z-index: 102;
  }

  @media (${theme.device.tablet}) {
    &:not(.no-padding) {
      padding: 0 6.67vw;
    }
  }
`;

export const SCNav = styled.nav`
  box-sizing: border-box;
  z-index: 102;

  display: flex;
  align-items: center;
  max-width: 1560px;

  margin: 0 auto;

  column-gap: 3vw;

  @media (${theme.screenSize.desktop}) {
    column-gap: 4vw;
  }

  &:hover > *:not(:hover) span {
    opacity: 0.3 !important;
  }

  @media (${theme.screenSizeMin.tablet}) {
    overflow: hidden;
    width: ${({ isOpen }) => (isOpen ? "100vw" : "none")};
  }

  @media (${theme.device.tablet}) {
    /* max-width: 668px; */
    justify-content: unset;
    position: absolute;
    left: 0;
    top: 75px;
    flex-direction: column;
    row-gap: 24px;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    transition: all 0.4s;
    text-transform: uppercase;
    height: ${({ isOpen }) => (isOpen ? "calc(100vh - 75px)" : 0)};
    overflow-y: auto;

    & > ${SCLink}:first-child {
      margin-top: 44px;
    }

    /* &:before {
      content: "";
      transition: height 0.4s;
      position: fixed;
      background: linear-gradient(to bottom, #0a0b10, #010101);
      width: 100vw;
      height: ${({ isOpen }) => (isOpen ? "calc(100vh - 45px)" : 0)};

      z-index: 101;
      left: 0;
    } */
  }

  @media (${theme.screenSize.mobile}) {
    /* max-width: 325px; */
  }
`;

export const SCContactUs = styled(Button)`
  white-space: nowrap;
  ${animate};
  @media (${theme.device.laptop}) {
    display: none;
  }
`;
