import React from "react";

import LogoIcon from "src/assets/icons/GreyApes.com.svg";
import SocialShare from "components/socialShare";
import { theme } from "src/styles/global";
import { Flex } from "src/styles/components";
import { Hr } from "src/styles/components";
import {
  Container,
  LogoContainer,
  Text,
  SCLink,
  AddressContainer,
  LinkContainer,
  Gap,
  IsTablet,
  TopButtonWrapper,
} from "./styled";
import ArrowIcon from "assets/icons/arrow-circle.svg";
import { links } from './const';

export const Tablet = () => {
  return (
    <IsTablet>
      <Container padding="60px 0">
        <LogoContainer lvl="max-width: 540px">
          <LogoIcon />
        </LogoContainer>

        <LinkContainer direction="column" gap="20px">
          {links.map(({ href, title }) => (
            <SCLink>
              <span
                onClick={() => {
                  if (
                    href.includes("#") &&
                    typeof window !== "undefined" &&
                    window.innerWidth < 769
                  ) {
                    const scrollItem = document.querySelector(".smooth-wrapper");
                    scrollItem.scrollTo({
                      top: document
                        .querySelector(href.replace("/", ""))
                        .getBoundingClientRect().top + scrollItem.scrollTop,
                      behavior: "smooth",
                    });
                  }
                }}
              >
                {title}
              </span>
            </SCLink>
          ))}
        </LinkContainer>
        <LinkContainer direction="column" gap="20px">
          <SCLink to={theme.links.instagram} target="_blank">
            Instagram
          </SCLink>
          <SCLink to={theme.links.facebook} target="_blank">
            Facebook
          </SCLink>
          <SCLink to={theme.links.behance} target="_blank">
            Behance
          </SCLink>
        </LinkContainer>
        <LinkContainer direction="column" gap="20px">
          <SCLink to={theme.links.dribbble} target="_blank">
            Dribbble
          </SCLink>
          <SCLink to={theme.links.telegram} target="_blank">
            Telegram
          </SCLink>
        </LinkContainer>
      </Container>
      <Hr />
      <Container padding="60px 0" align="flex-end" justify="center">
        <AddressContainer direction="column" justify="space-between">
          <SocialShare isGo />
          <Gap />
          <SCLink to="mailto:luciano.nonnis@greyapes.com">
            luciano.nonnis@greyapes.com
          </SCLink>
        </AddressContainer>
        <Flex direction="column" justify="space-between" align="flex-end">
          <Text>
            7240 Waverly St. <br />
            Office 212 <br />
            Ukraine, Zhytomir H2R 2Y8
          </Text>
        </Flex>
        <TopButtonWrapper
          onMouseDown={() => window.scroll({ top: 0, behavior: "smooth" })}
        >
          <ArrowIcon />
        </TopButtonWrapper>
      </Container>
    </IsTablet>
  );
};
