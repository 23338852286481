import React from 'react';
import {SCBurgerBtn} from "./burgerMenu.styled";

const BurgerMenu = ({ onClick, isOpen }) => {
  return (
    <SCBurgerBtn onClick={onClick} isOpen={isOpen}>
      <span />
      <span />
      <span />
    </SCBurgerBtn>
  );
};

export default BurgerMenu;
