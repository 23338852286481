export const links = [
  {
    href: "/#projects",
    title: "Projects",
  },
  {
    href: "/#services",
    title: "Services",
  },
  {
    href: "/#contact-us",
    title: "Contact Us",
  },
];
