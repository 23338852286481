import React, { useEffect, useRef, useState } from "react";
import { BtnLabel, Circle, Container, SendBtn } from "./contactUsSubmitStyled";
import ArrowIcon from "src/assets/icons/arrow-down.svg";

const ContactUsSubmit = ({ isOpen, ...props }) => {
  const timeoutId = useRef();
  const ref = useRef();
  const [isIntersecting, setIntersecting] = useState(false);

 

  useEffect(() => {
    const onScroll = () => {
      if (isOpen) {
        timeoutId.current = setTimeout(() => {
          const top = ref.current?.getBoundingClientRect().top;
          setIntersecting(top <= window.innerHeight);
        }, 750);
      } else {
        clearTimeout(timeoutId.current);
        setIntersecting(false);
      }
    };
    
    onScroll();
    document.addEventListener("scroll", onScroll);
    return () => document.removeEventListener("scroll", onScroll);
  }, [isOpen]);

  return (
    <Container ref={ref}>
      <Circle className={isIntersecting ? "" : "start"} />
      <Circle className={isIntersecting ? "" : "start"} />
      <Circle className={isIntersecting ? "" : "start"} />

      <SendBtn {...props}>
        <BtnLabel>Send a request</BtnLabel>
        <ArrowIcon />
      </SendBtn>
    </Container>
  );
};

export default ContactUsSubmit;
