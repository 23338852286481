import React, { useEffect, useState, useRef } from "react";
import { WorkButtonContainer, Label, WorkBtn, Circle } from "./styled";

export const FlyButton = ({
  label = "",
  direction = "right",
  delay = 2700,
  onClick,
}) => {
  const ref = useRef();
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const element =
      typeof window !== "undefined" && window.innerWidth <= 640
        ? document.querySelector(".smooth-wrapper")
        : document;

    let id;
    const onScroll = () => {
      if (isIntersecting) return;
      if (!ref.current) {
        setIntersecting(false);
        return;
      }
      clearTimeout(id);
      const top = ref.current?.getBoundingClientRect().top;
      if (top >= 0 && top <= window.innerHeight) {
        id = setTimeout(() => {
          setIntersecting(true);
        }, delay);
      }
    };

    onScroll();
    element.addEventListener("scroll", onScroll);
    return () => element.removeEventListener("scroll", onScroll);
  });

  return (
    <WorkButtonContainer ref={ref} direction={direction}>
      <Circle direction={direction} className={isIntersecting ? "" : "start"} />
      <Circle direction={direction} className={isIntersecting ? "" : "start"} />
      <Circle direction={direction} className={isIntersecting ? "" : "start"} />

      <WorkBtn onClick={onClick}>
        <Label direction={direction}>{label}</Label>
      </WorkBtn>
    </WorkButtonContainer>
  );
};
