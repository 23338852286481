import React from "react";
import styled from "styled-components";
import { SCLink, Text, TopButtonWrapper, IsMobile } from "./styled";
import { theme } from "styles/global";
import { Flex } from "src/styles/components";
import ArrowIcon from "assets/icons/arrow-circle.svg";

const FlexPadding = styled(Flex)`
  padding: 20px 0;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media (${theme.device.mobile}) {
    width: calc(100% - 85px);
  }
`;

export const CopyRight = styled(Text)`
  font-weight: 400;
  margin-right: 35px;
  @media (${theme.device.mobile}) {
    width: 100%;
  }
`;

const Link = styled(SCLink)`
  font-weight: 400;
  &:not(:last-child) {
    margin-right: 35px;
  }
`;

export const TermAndPolicy = ({ onTop }) => {
  return (
    <FlexPadding>
      <Container>
        <CopyRight width="80%">All rights reserved 2022 © Greyapes.</CopyRight>
        <Link as="a" href="/privacy-policy/">
          Privacy policy
        </Link>
        <Link as="a" href="/terms-and-use">
          Term of use
        </Link>
      </Container>
      <IsMobile>
        <TopButtonWrapper onMouseDown={onTop}>
          <ArrowIcon />
        </TopButtonWrapper>
      </IsMobile>
    </FlexPadding>
  );
};
