import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CustomEase } from "gsap/CustomEase";

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger, CustomEase);
  CustomEase.create(
    "custom",
    "M0,0,C0.2,0,0.185,0.42,0.318,0.626,0.463,0.852,0.822,-0.014,1,-0.014"
  );
}

let lastValue = 0;

export function smoothScroll(content, viewport, smoothness, ref) {
  content = gsap.utils.toArray(content)[0];
  const t = gsap.set(viewport || content.parentNode, {
    overflow: "hidden",
    position: "fixed",
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  });
  gsap.set(content, { overflow: "visible", width: "100%" });

  let getProp = gsap.getProperty(content),
    setProp = gsap.quickSetter(content, "y", "px"),
    height;

  function onResize() {
    height = content.clientHeight;
    document.body.style.height = height + "px";
  }
  onResize();
  window.addEventListener("resize", onResize);

  const ro = new ResizeObserver(function (e) {
    height = content.clientHeight;
    document.body.style.height = height + "px";
  });
  ro.observe(content);

  ScrollTrigger.scrollerProxy(content, {
    scrollTop(value) {
      return arguments.length ? setProp(-value) : -getProp("y");
    },
    getBoundingClientRect() {
      return {
        top: 0,
        left: 0,
        width: window.innerWidth,
        height: window.innerHeight,
      };
    },
  });

  return gsap.to(content, {
    y: () => document.documentElement.clientHeight - height,
    ease: "none",
    onUpdate: ScrollTrigger.update,
    scrollTrigger: {
      invalidateOnRefresh: true,
      start: 0,
      end: () => height - document.documentElement.clientHeight,
      scrub: smoothness || 1,
    },
  });
}
