import styled from "styled-components";

export const MainContainer = styled.section`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;

  margin: 0 auto;
  min-height: 100vh;
`;

export const Loader = styled.div`
  display: none;
  .onLoader {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    z-index: 1001;
  }
`;
