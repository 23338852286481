import React, { useEffect, useState, useRef } from "react";
import { useCookies } from "react-cookie";

import {
  AcceptStyle,
  CookiesContainer,
  CookiesContent,
  TextStyle,
} from "./cookiesWarningStyled";
import { Button, Flex, SCLink, Text } from "src/styles/components";
import { theme } from "src/styles/global";

import CookieIcon from "src/assets/icons/cookie.svg";

const CookiesWarning = () => {
  const ref = useRef();
  const [_, setUpdate] = useState(Math.random());
  const [isNone, setNone] = useState(
    typeof window !== "undefined" && !!localStorage.getItem("cookie")
  );
  const [cookies, setCookie] = useCookies(["my-accept-flag"]) || {};

  const onCookiesAccept = () => {
    setNone(true);
    setUpdate(Math.random());
    typeof window !== "undefined" && localStorage.setItem("cookie", "cookie");
    setCookie("my-accept-flag", true, { path: "/" });
  };

  useEffect(() => {
    if(ref.current) {
      try {
        ref.current.style.display = isNone ? "none" : "flex";
      } catch (_) {}
    }
  })

  return (
    <CookiesContainer
      ref={ref}
      style={{ display: isNone ? "none" : "flex" }}
      isNone={isNone || cookies["my-accept-flag"]}
    >
      <Flex align="center" justify="space-between" as={CookiesContent}>
        <Flex align="center" gap="22px">
          <CookieIcon width={56} />
          <Text color={theme.colors.santasGray} size={18} as={TextStyle}>
            This site uses cookies to give you the best browsing experience. By
            using this website you are agreeing to our{" "}
            <SCLink as="a" to="/cookie-policy/" size={18}>
              Cookie Policy.
            </SCLink>
          </Text>
        </Flex>

        <Button size={20} as={AcceptStyle} onClick={onCookiesAccept}>
          Accept
        </Button>
      </Flex>
    </CookiesContainer>
  );
};

export default CookiesWarning;
