import styled from "styled-components";
import {theme} from "../../styles/global";

export const TextAnimationWrapper = styled.div`
  overflow-y: hidden;
  min-height: 75px;
  margin-bottom: 8px;
  
  @media (${theme.screenSize.desktop}) {
    min-height: 55px;
  }

  @media (${theme.screenSize.laptop}) {
    min-height: 45px;
    margin-bottom: 6px;
  }

  @media (${theme.screenSize.tablet}) {
    min-height: 45px;
  }

  @media (${theme.screenSize.mobile}) {
    min-height: 35px;
  }
`;