import styled from "styled-components";
import { theme } from "src/styles/global";

export const Gap = styled.div`
  width: 35px;
`;

export const SocialButton = styled.button`
  height: 24px;
  border: none;
  cursor: pointer;
  color: ${({ color = theme.colors.scarpaFlow }) => color};
  transition: all 0.3s;

  display: flex;
  align-items: center;

  &:hover {
    opacity: 1 !important;
  }
`;

export const SocialContainer = styled.div`
  display: flex;
  justify-content: ${({ align = "space-between" }) => align};
  &:hover > *:not(:hover) {
    opacity: 0.3;
  }
`;
