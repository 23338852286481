import { css } from "styled-components";
import { theme } from "./global";

export const layoutSize = css`
  width: 81.25vw;
  max-width: 1560px;

  @media (${theme.screenSize.desktop}) {
    max-width: 1280px;
    width: 81.25vw;
  }

  @media (${theme.screenSize.laptop}) {
    max-width: 1000px;
    width: 83.33vw;
  }

  @media (${theme.screenSize.tablet}) {
    max-width: 668px;
    width: 86.98vw;
  }

  @media (${theme.screenSize.mobile}) {
    max-width: 325px;
    width: 86.66vw;
  }
`;
