import React, { useEffect, useState, useRef } from "react";
import { Div } from "./styled";

export const CustomScroll = () => {
  const isThumbDrag = useRef();
  const ref = useRef(null);
  const html = useRef(null);

  const height = 50;

  function onScroll() {
    const { innerHeight } = window;
    const { scrollHeight } = document.body;
    const top = Math.abs(document.body.getBoundingClientRect().top);

    let scrollThumbPosition;
    if (top + innerHeight === scrollHeight) {
      scrollThumbPosition = innerHeight - height;
    } else {
      scrollThumbPosition = innerHeight * top / scrollHeight;
    }

    if (html.current) {
      html.current.style.transform = `translateY(${scrollThumbPosition}px)`;
      html.current.style.opacity = 1;

      clearTimeout(ref.current);
      ref.current = setTimeout(() => {
        html.current.style.opacity = 0;
      }, 3000);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
      clearTimeout(ref.current);
    };
  }, []);

  const handleThumbMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
    isThumbDrag.current = true;
  };

  const handleThumbMouseUp = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (isThumbDrag.current) {
      isThumbDrag.current = false;
    }
  };

  const handleThumbMouseMove = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (isThumbDrag.current) {
      const { innerHeight } = window;
      const { scrollHeight } = document.body;
      const newTop = (event.clientY * scrollHeight) / innerHeight;
      onScroll();
      window.scroll(0, newTop);
    }
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleThumbMouseMove);
    window.addEventListener('mouseup', handleThumbMouseUp);
    return () => {
      window.removeEventListener('mousemove', handleThumbMouseMove);
      window.removeEventListener('mouseup', handleThumbMouseUp);
    };
  }, [handleThumbMouseMove, handleThumbMouseUp]);

  return <Div ref={html} height={height} onMouseDown={handleThumbMouseDown}/>;
};
