import React from "react";
import SocialShare from "components/socialShare";
import { theme } from "src/styles/global";
import { Hr } from "src/styles/components";
import { Flex } from "src/styles/components";

import {
  MobileContainer,
  Gap,
  Text,
  SCLink,
  LinkContainer,
  IsMobile,
} from "./styled";
import { links } from "./const";

export const Mobile = () => {
  return (
    <IsMobile>
      <MobileContainer
        padding="50px 0"
        direction="column"
        justify="center"
        align="center"
      >
        <Text align="center">
          7240 Waverly St. <br />
          Office 212 <br />
          Ukraine, Zhytomir H2R 2Y8
        </Text>
        <Gap height={50} />
        <SocialShare isGo />
        <Gap height={50} />
        <Flex direction="column" justify="space-between" align="flex-end">
          <SCLink to="mailto:luciano.nonnis@greyapes.com">
            luciano.nonnis@greyapes.com
          </SCLink>
        </Flex>
      </MobileContainer>
      <Hr />
      <MobileContainer padding="50px 0" justify="space-around">
        <Flex direction="column" gap="20px" justify="flex-start">
          {links.map(({ href, title }, i) => (
            <SCLink key={i}>
              <span
                onClick={() => {
                  if (
                    href.includes("#") &&
                    typeof window !== "undefined" &&
                    window.innerWidth < 769
                  ) {
                    const scrollItem = document.querySelector(".smooth-wrapper");
                    scrollItem.scrollTo({
                      top: document
                        .querySelector(href.replace("/", ""))
                        .getBoundingClientRect().top + scrollItem.scrollTop,
                      behavior: "smooth",
                    });
                  }
                }}
              >
                {title}
              </span>
            </SCLink>
          ))}
        </Flex>
        <LinkContainer direction="column" gap="20px">
          <SCLink to={theme.links.instagram} target="_blank">
            Instagram
          </SCLink>
          <SCLink to={theme.links.facebook} target="_blank">
            Facebook
          </SCLink>
          <SCLink to={theme.links.behance} target="_blank">
            Behance
          </SCLink>
          <SCLink to={theme.links.dribbble} target="_blank">
            Dribbble
          </SCLink>
          <SCLink to={theme.links.telegram} target="_blank">
            Telegram
          </SCLink>
        </LinkContainer>
      </MobileContainer>
    </IsMobile>
  );
};
