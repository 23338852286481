import styled, { keyframes } from "styled-components";

const rotateAnim = keyframes`
  0% {
    transform: rotate(0deg)
  }
  25% {
    transform: rotate(90deg)
  }
  50% {
    transform: rotate(90deg)
  }
  75% {
    transform: rotate(180deg)
  }
  100% {
    transform: rotate(180deg)
  }
`;

const translateAnimTop = keyframes`
  0% {
    transform: translateY(0px);
    background-color: rgba(255, 255, 255, 1);
  }
  25% {
    transform: translateY(0px);
    background-color: rgba(255, 255, 255, 1);
  }
  50% {
    transform: translateY(-40px);
    background-color: rgba(255, 255, 255, 0);
  }
  75% {
    transform: translateY(-40px);
    background-color: rgba(255, 255, 255, 0);
  }
  100% {
    transform: translateY(0px);
    background-color: #fff;
    background-color: rgba(255, 255, 255, 1);
  }
`;

const translateAnimBottom = keyframes`
  0% {
    transform: translateY(0px);
    background-color: rgba(255, 255, 255, 1);
  }
  25% {
    transform: translateY(0px);
    background-color: rgba(255, 255, 255, 1);
  }
  50% {
    transform: translateY(40px);
    background-color: rgba(255, 255, 255, 0);
  }
  75% {
    transform: translateY(40px);
    background-color: rgba(255, 255, 255, 0);
  }
  100% {
    transform: translateY(0px);
    background-color: rgba(255, 255, 255, 1);
  }
`;

const translateAnimLeft = keyframes`
  0% {
    transform: translateX(0px);
    background-color: rgba(255, 255, 255, 1);
  }

  25% {
    transform: translateX(0px);
    background-color: rgba(255, 255, 255, 1);
  }

  50% {
    transform: translateX(-40px);
    background-color: rgba(255, 255, 255, 0);
  }

  75% {
    transform: translateX(-40px);
    background-color: rgba(255, 255, 255, 0);
  }

  100% {
    transform: translateX(0px);
    background-color: rgba(255, 255, 255, 1);
  }
`;

const translateAnimRight = keyframes`
  0% {
    transform: translateX(0px);
    background-color: rgba(255, 255, 255, 1);
  }
  25% {
    transform: translateX(0px);
    background-color: rgba(255, 255, 255, 1);
  }
  50% {
    transform: translateX(40px);
    background-color: rgba(255, 255, 255, 0);
  }
  75% {
    transform: translateX(40px);
    background-color: rgba(255, 255, 255, 0);
  }
  100% {
    transform: translateX(0px);
    background-color: rgba(255, 255, 255, 1);
  }
`;

export const LoaderWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  position: absolute;
`;

export const LoaderAnimated = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100px;
  height: 100px;
  animation-name: ${rotateAnim};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;

  div {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    animation-name: translateAnim;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;

    &:nth-child(1) {
      top: -20px;
      animation-name: ${translateAnimTop};
    }

    &:nth-child(2) {
      left: 20px;
      animation-name: ${translateAnimRight};
    }

    &:nth-child(3) {
      top: 20px;
      animation-name: ${translateAnimBottom};
    }

    &:nth-child(4) {
      left: -20px;
      animation-name: ${translateAnimLeft};
    }
  }
`;
