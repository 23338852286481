import styled from "styled-components";
import HomeBannerSeparatorImage from "src/assets/images/home-banner-separator.png";
import { theme } from "src/styles/global";

export const WaveComponent = styled.div`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  max-width: 100%;

  height: 5vw;
  z-index: 1;
  display: block;
  bottom: 0;
  z-index: 101;
  position: relative;
  background-image: url(${HomeBannerSeparatorImage});
  transform-origin: bottom center;


  
  @media (${theme.screenSize.mobile}) {
    transform: translateY(-15px);
  }
`;
