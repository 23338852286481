import styled from "styled-components";
import { theme } from "src/styles/global";

export const ShareLikeWrapper = styled.div` 
  position: relative;
  margin-left: 60px;
  display: flex;
  flex-direction: column;
  align-items: end;
  height: fit-content;
  z-index: 1000;
  
  &.share-tablet {
    display: none;
  }
  
  button:first-child {
    background-color: ${theme.colors.woodsmoke};
    position: absolute;
    top: 0;
    right: 0;
  }
  
  button:last-child {
    position: relative;
    top: 100px;    
  }

  @media (${theme.screenSize.laptop}) {
    margin-left: 0;
    flex-direction: row;
    
    &.share-desktop {
      display: none;
    }

    &.share-tablet {
      display: flex;
    }

    button:first-child {           
      right: 90px;
    }

    button:last-child {      
      top: 0;
      margin-left: 100px;
    }
  }

  @media (max-width: 760px) {
    button:first-child {
      right: 82px;
    }
  }

  @media (max-width: 460px) {
    button:first-child {
      right: 65px;
    }

    button:last-child {      
      margin-left: 75px;
    }
  }
`;

export const ActionButton = styled.button`
  background: none;
  min-width: 80px;
  height: 80px;
  border: 1px solid #54525c;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; 
  cursor: pointer;
  transition: 0.2s all linear;

  > svg {
    color: ${theme.colors.scarpaFlow};  
  }  
  
  &:after {
    margin-top: 5px;
    content: "${({ content }) => content}";
    color: #555;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }
  
  &:hover {
    border-color: ${theme.colors.white};

    > svg,  &:after  {
      color: ${theme.colors.white};
    }
  }
  
  &.like {
    border-color: ${theme.colors.red};
    
    svg {
      color: ${theme.colors.red};
    }
    
    &:after {
      color: ${theme.colors.red};
    }
  }
  
  &.social {
    flex-direction: row;    
    
    &:after {
      display: none;
    }
    
    a {
      color: ${theme.colors.scarpaFlow};     
      display: none;
    }
  }
  
  &.social.share {
    min-width: 250px;
    padding: 0 30px;
    justify-content: space-between;
    
    &:hover {
      > svg {
        color: ${theme.colors.scarpaFlow};
      }
    }
    
    > svg {
      width: 25px;
      
      &:hover {
        color: ${theme.colors.white};
      }
    }    

    a {
      display: block;
      
      svg {
        width: 25px;
        transition: 0.2s linear;
        
        &:hover {
          color: ${theme.colors.white};
        }
      }
    }
  }

  @media (max-width: 760px) {    
    height: 65px;
    min-width: 65px;

    > svg {
      width: 18px;
    }

    &:after {
      font-size: 14px;
    }

    &.social.share {      
      min-width: 180px;
      padding: 0 20px;

      > svg {
        width: 18px;
      }
      
      a {
        svg {
          width: 18px;
        }
      }
    }
  }

  @media (max-width: 460px) {
    height: 54px;
    min-width: 54px;

    > svg {
      width: 16px;
    }

    &:after {
      font-size: 10px;
    }

    &.social.share {
      min-width: 180px;
      padding: 0 20px;
      > svg {
        width: 16px;
      }
      a {
        svg {
          width: 16px;
        }
      }
    }    
  }  
`;