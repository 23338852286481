import styled, { css } from "styled-components";
import { Flex, Text } from "src/styles/components";
import { globalWrapperSize, theme } from "src/styles/global";

import ArrowCircleIcon from "src/assets/icons/arrow-circle-2.svg";
import PlanetImage from "src/assets/images/planet.png";

import HomeBannerImage from "src/assets/images/home-banner.png";
import { layoutSize } from "styles/mixin";

const backgroundImage = css`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  max-width: 100%;
`;

export const Label = styled(Text)`
  color: ${({ color }) => color};
  margin-bottom: ${({ bottom = "18px" }) => bottom};
  max-width: 343px;
  text-align: ${({ isRight, textAlign = "" }) =>
    textAlign || (isRight ? "right" : "left")};

  @media (${theme.device.tablet}) {
    max-width: 302px;
  }

  @media (${theme.device.mobile}) {
    font-size: 10px;
    max-width: 157px;
  }
`;

export const FullWidthLabel = styled(Text)`
  color: ${({ color }) => color};
  text-align: justify;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;

  &:after {
    content: "";
    display: inline-block;
    width: 100%;
  }
`;

export const SubLabel = styled.span`
  font-family: ${({ family = "Montserrat" }) => `${family}, sans-serif`};
  font-style: normal;
  font-weight: 400;
  font-size: ${({ size = 16 }) => size + "px"};
  color: #c4bfce;
  line-height: 150%;

  &:hover {
    color: ${({ isOffHover }) => (isOffHover ? "#c4bfce" : "white")};
  }

  @media (max-width: 640px) {
    line-height: 30px;
  }
`;

export const ContainerTopHomeBanner = styled(Flex)`
  ${backgroundImage};
  flex-direction: column;
  justify-content: space-between;
  background-image: url(${HomeBannerImage});
  position: relative;
  background: rgba(16, 16, 16, 1);

  padding: 80px 0 180px 0;

  height: 55vw;
  max-height: 100vh;

  @media (${theme.screenSize.fullScreen}) {
    padding: 80px 0 9.375vw 0;
  }

  @media (${theme.screenSize.desktop}) {
    padding: 80px 0 5vw 0;
    height: 53.75vw;
    max-height: 860px;
  }

  @media (${theme.screenSize.laptop}) {
    height: 52vw;
    max-height: 625px;
  }

  @media (${theme.screenSize.tablet}) {
    height: 110vw;
    max-height: 820px;
  }

  @media (max-width: 640px) {
    height: 180vw;
    max-height: 700px;
    padding: 80px 0 10vw 0;
  }
`;

export const LoaderHomeBanner = styled.div`
  z-index: 10001;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background: black;

  & img {
    width: 50px;
    height: 50px;
  }

  @media (${theme.device.mobile}) {
    display: none!important;
  }
`;

export const SocialContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ align = "space-between" }) => align};
  @media (${theme.device.mobile}) {
    display: none;
  }
`;

export const ContentContainer = styled(Flex)`
  position: relative;
  display: flex;
  justify-content: space-between;
  z-index: 1;

  ${layoutSize};
  margin: 0 auto;

  &:first-child {
    margin-top: 130px;
  }

  @media (${theme.screenSize.fullScreen}) {
    &:first-child {
      margin-top: 6vw;
    }
  }

  @media (${theme.screenSize.desktop}) {
    &:first-child {
      margin-top: 5vw;
    }
    &:last-child {
      margin-bottom: 5vw;
    }
  }

  @media (${theme.screenSize.laptop}) {
    &:first-child {
      margin-top: 5vw;
    }
    &:last-child {
      margin-bottom: 5vw;
    }
  }

  @media (${theme.screenSize.tablet}) {
    &:first-child {
      margin-top: 9vw;
    }
    &:last-child {
      margin-bottom: 15vw;
    }
  }

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;

    &:first-child {
      margin-top: 9vw;
    }
    &:last-child {
      margin-bottom: 5vw;
    }
  }
`;

export const IconArrow = styled(ArrowCircleIcon)`
  margin-right: 14px;
  margin-top: 1em;
`;

export const BrandContainer = styled(Flex)`
  height: fit-content;

  @media (${theme.device.mobile}) {
    opacity: 0;
  }
`;

export const FlyContainerFooter = styled(Flex)`
  width: fit-content;
  margin-top: 100px;
  @media (${theme.device.mobile}) {
    margin: 0 auto;
    position: relative;
    left: -10vw;
  }
`;

export const Separator = styled.div`
  min-height: ${({ size = 0 }) => size}px;
  @media (${theme.device.mobile}) {
    min-height: ${({ sizeMobile = 0, size = 0 }) => sizeMobile || size}px;
  }
`;

export const EmptyBlock = styled.div`
  @media (${theme.device.mobile}) {
    height: 100px;
  }
`;

export const ContainerProjectBanner = styled(Flex)`
  position: relative;
  ${globalWrapperSize};
  margin: 90px auto 0;
  height: calc(30.5vw);

  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  h1 {
    max-width: 950px;
  }

  span {
    display: inherit;
    max-width: 860px;
  }

  @media (${theme.screenSize.laptop}) {
    margin: 150px auto 30px;
    justify-content: end;
    height: fit-content;
  }

  @media (${theme.screenSize.tablet}) {
    align-items: flex-start;
    margin-top: 10px;

    margin: 0 auto 50px;

    section {
      height: 30px;
    }
    section {
      height: 30px;
    }

    span {
      margin-bottom: 70px;
    }
  }

  @media (max-width: 640px) {
    margin: 0 auto 50px;

    span {
      font-size: 18px;
      margin-bottom: 0;
    }
  }

  & > * {
    z-index: 1;
  }
`;

export const Video = styled.video`
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  transform: translateY(-80px);
  transition: opacity 1s cubic-bezier(0.14, 0.48, 0, 0.96);
`;

export const ProjectImage = styled.img`
  position: absolute;
  width: calc(50vw);
  height: 30vw;
  right: 47%;
  top: 4px;
  z-index: 0;
  object-fit: contain;
  max-height: 600px;

  @media (${theme.screenSize.desktop}) {
    right: 40%;
    width: calc(50vw);
    height: 30vw;
  }

  @media (${theme.device.laptop}) {
    right: 40%;
    width: calc(50vw);
    height: 40vw;
    top: 10px;
  }

  @media (${theme.screenSize.laptop}) {
    width: 475px;
    right: 57vw;
    top: -30px;
  }

  @media (${theme.screenSize.tablet}) {
    position: relative;
    top: 75px;
    right: 0;
    width: 607px;
    height: 440px;
  }

  @media (max-width: 640px) {
    width: 320px;
    height: 225px;
    right: -5vw;
    top: 45px;
    margin-top: 30px;
  }

  @media (max-width: 400px) {
    right: -2vw;
  }
`;

export const ProjectVideo = styled(Video)`
  position: absolute;
  width: calc(50vw - 50px);
  height: 37.5vw;
  z-index: 0;
  right: 50%;

  @media (${theme.device.tablet}) {
    right: 7.5vw;
    top: 50px;
    width: 85vw;
    height: 63.75vw;
  }
`;

const layout = css`
  width: 81.25vw;
  max-width: 1400px;

  @media (${theme.screenSize.desktop}) {
    max-width: 1280px;
    width: 81.25vw;
  }

  @media (${theme.screenSize.laptop}) {
    max-width: 1000px;
    width: 83.33vw;
  }

  @media (${theme.screenSize.tablet}) {
    max-width: 668px;
    width: 86.98vw;
  }

  @media (${theme.screenSize.mobile}) {
    max-width: 325px;
    width: 86.66vw;
  }
`;

export const SingleProjectContainer = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 150px;
  ${layout};

  @media (max-width: 760px) {
    padding-top: 110px;
  }
`;

// TODO FOOTER

export const Gap = styled.section`
  height: ${({ height = 17 }) => height}px;
`;

export const Planet = styled.div`
  width: 100%;
  max-width: 780px;
  height: 100%;
  display: block;
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, 0) scale(2);

  background-image: url(${PlanetImage});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 0;

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;

    box-shadow: 0 0 230px 230px rgb(41, 40, 67, 36%);
  }

  @media (${theme.device.mobile}) {
    display: none;
  }
`;

export const ContainerFooterBanner = styled.section`
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  max-height: 950px;
  height: 62.5vw;

  width: 100vw;
  /* background: linear-gradient(183deg, #0a0b10, rgba(55, 55, 55, 0.1)); */

  @media (${theme.screenSize.desktop}) {
    max-height: 900px;
    height: 62.5vw;
  }

  @media (${theme.screenSize.laptop}) {
    max-height: 825px;
    height: 107vw;
  }

  @media (${theme.screenSize.tablet}) {
    max-height: 1080px;
    height: 300vw;
  }
`;

export const MobilePlanet = styled.div`
  position: relative;
  min-height: 208px;
  max-height: 460px;
  z-index: 0;

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(${PlanetImage});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transform: scale(2.9);
    opacity: 0;
  }

  display: none;

  @media (${theme.device.mobile}) {
    display: block;
  }

  @media (max-width: 640px) {
    &:after {
      left: -80px;
    }
  }
`;

export const AddressPulse = styled.div`
  position: absolute;
  width: ${({ scale }) => scale * 100}%;
  height: ${({ scale }) => scale * 100}%;

  z-index: 1;

  border: 1px dotted white;
  border-radius: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  animation: pulseAnimation ${({ scale }) => scale / 4}s infinite;
  animation-delay: ${({ delay }) => delay}s;
`;

export const AddressDot = styled.div`
  background: white;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  top: 35%;
  right: 56%;
  opacity: 0;
  transition: 0.3s all linear;
  transform: translate(calc(-50% + 6vw), calc(-50% - 5vw));

  &.showAddress {
    opacity: 1;
  }

  @media (${theme.screenSize.desktop}) {
    transform: translate(calc(-50% + 6.5vw), calc(-50% - 6.5vw));
  }

  @media (${theme.screenSize.laptop}) {
    top: 31%;
    right: 57%;
    transform: translate(calc(-50% + 7.5vw), calc(-50% - 7vw));
  }

  @media (max-width: 768px) {
    top: 34%;
  }

  @media (${theme.device.mobile}) {
    top: 28%;
  }

  @media (max-width: 640px) {
    transform: translate(calc(-50% + 15vw), calc(-50% - 50vw));
    right: 83%;
    top: 46%;
  }

  @media (max-width: 376px) {
    top: 44%;
    right: 85%;
  }
`;

export const Content = styled.section`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;

  padding: 7vw 0;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${layoutSize};

  @media (min-width: 1600px) {
    /* padding: 140px 0; */
  }

  @media (${theme.screenSize.desktop}) {
    padding: 8.75vw 0;
  }

  @media (${theme.screenSize.laptop}) {
    padding: 5vw 0;
  }

  @media (${theme.device.mobile}) {
    background-image: none;
    padding: 9vw 0;
  }

  @media (${theme.screenSize.mobile}) {
    background-image: none;
    padding: 15vw 0;
  }
`;

export const ContentRow = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 30px;
  z-index: 1;

  @media (${theme.device.mobile}) {
    padding-top: 149px;

    &.topRow {
      position: relative;
      top: -155px;

      section {
        height: 30px;
      }
    }

    &.bottomRow {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      align-items: center;

      & > * {
        width: 100%;
      }
    }
  }

  @media (max-width: 640px) {
    padding-top: 156px;
  }

  @media (max-width: 376px) {
    padding-top: 143px;
  }
`;

export const ButtonContainer = styled.div`
  & > *:first-child {
    line-height: 100%;
  }
  @media (${theme.device.mobile}) {
    & > *:first-child {
      margin: 50px 0 40px 0;
      display: block;
      text-align: center;
    }
  }
`;

export const Image = styled.img`
  display: block;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`;

export const SingleProjectHeader = styled.div`
  margin-bottom: 150px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 110.5%;

  div {
    &:first-child {
      max-width: 810px;
    }
  }

  & [data-social] {
    /* display: none; */
  }

  & > *:first-child {
    flex: 1;
    max-width: 75%;
  }

  @media (${theme.screenSize.laptop}) {
    width: 100%;
    margin-bottom: 60px;

    & > *:first-child {
      max-width: 50%;
    }

    & [data-social] {
      display: flex;
    }
  }

  @media (${theme.screenSize.tablet}) {
    & > *:first-child {
      max-width: 100%;
    }
    & > *:last-child {
      display: none;
    }
  }

  @media (max-width: 760px) {
    order: -1;
  }

  @media (max-width: 460px) {
    margin-bottom: 30px;
  }
`;

export const DateButton = styled.div`
  background: rgba(36, 34, 41, 0.5);
  padding: 28px 35px;
  border-radius: 45px;
  max-width: 280px;
  min-width: 240px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  & svg {
    transform: translateX(20%);
  }

  & * {
    fill: #555;
  }

  &:before {
    content: "${({ date = "Sep 25, 2020" }) => date}";
    color: #555;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }

  &:after {
    content: "${({ view = 1 }) => view}";
    color: #555;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }

  @media (max-width: 760px) {
    height: 65px;
    min-width: 215px;
  }

  @media (max-width: 460px) {
    height: 54px;
    min-width: 190px;
    padding: 16px 23px;

    &:before,
    &:after {
      font-size: 14px;
    }
  }
`;

export const ControlWrapper = styled.div`
  justify-content: ${({ justify = "space-between" }) => justify};
  gap: ${({ gap = "20px" }) => gap};
  flex-wrap: ${({ wrap = "wrap" }) => wrap};
  display: ${({ display = "none" }) => display};
  max-width: ${({ maxWidth = "50%" }) => maxWidth};

  @media (${theme.screenSize.laptop}) {
    display: flex;
  }
`;

export const ControlWrapperTable = styled.div`
  display: none;
  margin-bottom: 50px;
  justify-content: space-between;
  align-items: flex-start;

  > svg {
    cursor: pointer;
    transition: 0.1s all linear;
    color: ${theme.colors.scarpaFlow};
  }

  > svg:hover {
    color: ${theme.colors.white};
  }

  @media (max-width: 1024px) {
    display: flex;
  }

  @media (max-width: 760px) {
    > svg {
      height: 65px;
      width: 65px;
    }
  }

  @media (max-width: 600px) {
    justify-content: center;

    > svg {
      display: none;
    }
  }
`;

export const ContentWrapper = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ContentNextPrevWrapper = styled.section`
  z-index: 101;
  display: flex;
  justify-content: space-between;
  width: 120%;
  position: absolute;

  svg {
    cursor: pointer;
    transition: 0.1s all linear;
    color: ${theme.colors.scarpaFlow};
  }

  svg:hover {
    color: ${theme.colors.white};
  }

  @media (${theme.screenSize.laptop}) {
    width: 119%;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const SingeImage = styled.img`
  ${layout};
  margin: 0 auto;
  position: relative;
  z-index: 102;
`;

export const SingeVideo = styled.video`
  ${layout};
  margin: 0 auto;
`;

export const GeneralWrapper = styled.div`
  display: flex;
  height: 80px;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const MobileNavWrapper = styled.div`
  display: none;
  justify-content: space-between;
  min-width: 180px;

  > svg {
    cursor: pointer;
    transition: 0.1s all linear;
    color: ${theme.colors.scarpaFlow};
  }

  > svg:hover {
    color: ${theme.colors.white};
  }

  @media (max-width: 1024px) {
    display: flex;
    margin-bottom: 40px;
    margin-top: 100px;
  }

  @media (max-width: 760px) {
    > svg {
      height: 65px;
      width: 65px;
    }
  }

  @media (max-width: 640px) {
    margin-top: 60px;
  }

  @media (max-width: 460px) {
    > svg {
      height: 54px;
      width: 54px;
    }
  }

  @media (${theme.screenSize.mobile}) {
    min-width: 140px;

    & > svg,
    button {
      width: 60px;
      height: 60px;
      min-width: 60px;
      min-height: 60px;
    }
  }
`;
