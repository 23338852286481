import React, { useEffect, useState, useRef } from "react";
import {
  SingleProjectContainer,
  DateButton,
  SingleProjectHeader,
  ControlWrapper,
  ControlWrapperTable,
  ContentWrapper,
  SingeImage,
  SingeVideo,
  MobileNavWrapper,
  ContentNextPrevWrapper,
  GeneralWrapper,
} from "./styled";
import { Title } from "components/typography";
import ShowSvg from "assets/icons/show.svg";
import PreviousArrow from "assets/icons/previous-arrow.svg";
import NextArrow from "assets/icons/next-arrow.svg";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ShareSocial } from "components/shareSocial";
import { useView, useLike } from "api";

export const TopProjectBanner = ({
  title = "",
  subTitle = "",
  folder = "",
  contents = [],
  prev,
  next,
  slug,
  date,
}) => {
  const refPromise = useRef();

  const { view } = useView(slug);
  const { like: totalLike, isLike, setLikeHandler } = useLike(slug);

  const [mount, setMount] = useState(false);
  const ref = useRef();

  useEffect(() => {
    if (mount) {
      ScrollTrigger.create({
        scroller: ".content-wrapper",
        trigger: ".action-btn",
        start: "top bottom",
        end: "bottom bottom+=50px",
        onUpdate: ({ progress }) => {
          if (ref.current && progress < 1) {
            const { top } = document
              .querySelector(".action-btn")
              .getBoundingClientRect();
            ref.current.style.transform = `translate(-8%, ${
              -top + 0.9 * window.innerHeight
            }px)`;
          }
        },
      });

      ScrollTrigger.create({
        scroller: ".content-wrapper",
        trigger: ".single-page video",
        start: "top center",
        end: "bottom center",
        onEnter: ({ trigger }) => {
          try {
            refPromise.current = trigger?.play();
          } catch (_) {}
        },
        onEnterBack: ({ trigger }) => {
          try {
            refPromise.current = trigger?.play();
          } catch (_) {}
        },
        onLeave: ({ trigger }) => {
          try {
            if(refPromise.current) {
              refPromise.current.then(() => {}).finally(() => trigger?.pause());
            }
          } catch (_) {}
        },
        onLeaveBack: ({ trigger }) => {
          try {
            if(refPromise.current) {
              refPromise.current.then(() => {}).finally(() => trigger?.pause());
            }
          } catch (_) {}
        },
      });
    } else {
      setMount(true);
    }
  }, [mount]);

  const onChangeProject = (link) => {
    if (typeof window !== "undefined" && link) {
      window.location.href = `/projects/${link}/`;
    }
  };

  return (
    <SingleProjectContainer className="single-page">
      <ControlWrapperTable>
        <PreviousArrow onClick={() => onChangeProject(prev)} />
        <ControlWrapper gap="0" wrap="none" maxWidth="100%">
          <DateButton date={date}>
            <ShowSvg />
          </DateButton>
          <ShareSocial
            like={isLike}
            totalLike={totalLike}
            setLikeHandler={setLikeHandler}
          />
        </ControlWrapper>
        <NextArrow onClick={() => onChangeProject(next)} />
      </ControlWrapperTable>

      <SingleProjectHeader className="share-link">
        <Title title={title} subTitle={subTitle} />
        <GeneralWrapper>
          <ControlWrapper display="flex" justify="flex-end">
            <DateButton view={view} date={date}>
              <ShowSvg />
            </DateButton>
          </ControlWrapper>
          <ShareSocial
            className="share-desktop"
            isScrollShare
            like={isLike}
            totalLike={totalLike}
            setLikeHandler={setLikeHandler}
          />
          <ShareSocial
            className="share-tablet"
            like={isLike}
            totalLike={totalLike}
            setLikeHandler={setLikeHandler}
          />
        </GeneralWrapper>
      </SingleProjectHeader>
      <ContentWrapper className="action-btn">
        <ContentNextPrevWrapper ref={ref}>
          <PreviousArrow onClick={() => onChangeProject(prev)} />
          <NextArrow onClick={() => onChangeProject(next)} />
        </ContentNextPrevWrapper>
        {contents.map((e, i) => {
          if (e.includes("mp4")) {
            return (
              <SingeVideo
                key={`key-${i}`}
                src={`/projects/${folder}/${e}`}
                muted="muted"
                loop
              />
            );
          }

          return (
            <SingeImage key={`key-${i}`} src={`/projects/${folder}/${e}`} />
          );
        })}
        <div className="action-end" />
        <MobileNavWrapper>
          <PreviousArrow onClick={() => onChangeProject(prev)} />
          <ShareSocial
            like={isLike}
            totalLike={totalLike}
            setLikeHandler={setLikeHandler}
          />
          <NextArrow onClick={() => onChangeProject(next)} />
        </MobileNavWrapper>
      </ContentWrapper>
    </SingleProjectContainer>
  );
};
